<template>
  <div :style="varColor">
    <div v-if="isEnvelopes">
      <section-all
        :colors="colors"
        :couple="couple"
        :dalil="dalil"
        :date="date"
        :event="event"
        :hour="hour"
        :isStorySlide="isStorySlide"
        :isTheme="isTheme"
        :live="live"
        :minute="minute"
        :month="month"
        :phone="phone"
        :photos="photos"
        :stories="stories"
        :videos="videos"
        :year="year"
        :audios="audios"
        @pausings="pauses"
        @playings="plays"
        :calendar="calendar"
      />
    </div>
    <div class="h-sm-100" v-else>
      <envelope
        :theme="isTheme"
        :colors="colors"
        @showInvite="openEnvelope"
        :to="to"
      />
    </div>
  </div>
</template>

<script>
import Envelope from "@/components/Envelope.vue";
const SectionAll = () => import("@/components/SectionAll.vue");

export default {
  data() {
    return {
      audios: {
        file: new Audio(
          "https://assets.dafidea.xyz/audio/marsha.mp3"
        ),
        isPlaying: false,
      },
      isStorySlide: true,
      envelope: true,
      isTheme: 2,
      to: this.$route.query.to,
      colors: {
        primary: "121, 103, 96",
        secondary: "151, 129, 120",
        color3: "224, 217, 215",
        color4: "244, 242, 241",
      },
    };
  },
  components: {
    Envelope,
    SectionAll,
  },
  props: [
    "couple",
    "live",
    "event",
    "year",
    "month",
    "date",
    "hour",
    "minute",
    "photos",
    "phone",
    "stories",
    "dalil",
    "videos",
    "calendar",
  ],
  computed: {
    isEnvelopes() {
      return !this.envelope;
    },
    varColor() {
      return {
        "--hy-primary": this.colors.primary,
        "--hy-secondary": this.colors.secondary,
        "--hy-color-3": this.colors.color3,
        "--hy-color-4": this.colors.color4,
      };
    },
  },
  methods: {
    openEnvelope() {
      this.envelope = !this.envelope;
      this.audios.file.play();
      this.audios.isPlaying = true;
    },
    pauses() {
      this.audios.isPlaying = false;
      this.audios.file.pause();
    },
    plays() {
      this.audios.isPlaying = true;
      this.audios.file.play();
    },
  },
};
</script>
