<template>
  <div class="h-sm-100">
    <div
      class="
        h-sm-100
        d-flex
        align-items-center
        justify-content-between
        flex-column
      "
      :style="bgEnvelope"
    >
      <CornerRight class="align-self-end" width="200px" />
      <div class="align-self-center flex-column d-flex" style="max-width: 85%">
        <EnvelopeIslami height="250px" />
        <div class="card card-envelope">
          <div class="card-body text-center">
            <span>
              Kepada Yth. <br />
              Bapak/Ibu/Saudara
            </span>
            <h1
              class="h1-envelope my-3"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              {{ to }}
            </h1>
            <button
              @click="$emit('showInvite')"
              class="btn-hy-primary px-4 btn"
            >
              Buka Undangan
            </button>
          </div>
        </div>
      </div>
      <CornerLeft class="align-self-start" width="200px" />
    </div>
    <div class="envelope-back"></div>
  </div>
</template>

<script>
const CornerRight = () =>
  import("@/assets/illustrations/envelope-corner-right.svg");
const CornerLeft = () =>
  import("@/assets/illustrations/envelope-corner-left.svg");
const EnvelopeIslami = () =>
  import("@/assets/illustrations/envelope-islami.svg");

export default {
  name: "Envelope",
  props: ["to", "colors", "theme"],
  components: {
    CornerRight,
    CornerLeft,
    EnvelopeIslami,
  },
  computed: {
    bgEnvelope() {
      return `background: rgb(${this.colors.color4}); color: rgb(${this.colors.primary})`;
    },
  },
};
</script>
